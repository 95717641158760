import { render, staticRenderFns } from "./CompanyRelevancy.vue?vue&type=template&id=bfb75afc&scoped=true&"
import script from "./CompanyRelevancy.vue?vue&type=script&lang=js&"
export * from "./CompanyRelevancy.vue?vue&type=script&lang=js&"
import style0 from "./CompanyRelevancy.vue?vue&type=style&index=0&id=bfb75afc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfb75afc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAutocomplete,VBtn,VCol,VForm,VIcon,VListItemTitle,VProgressLinear,VRow,VSelect,VTooltip})
